export const initialRolls = Array(6).fill().map(() => ({ value: 0, selected: false, locked: false }))

const Midnight = {}

Midnight.rollDice = (playerId, gameState, params) => {
    // TODO make sure this is a valid roll request
    // TODO make sure it is the user's turn... etc...

    const { lockedRolls } = params

    // Duplicate the GameState to avoid mutating the original
    gameState = JSON.parse(JSON.stringify(gameState))

    // If every roll is 0, reset rollHistory (first roll)
    if (lockedRolls.every(roll => roll.value == 0)) {
        gameState.rollHistory = []
    }

    // Ensure missing players ar added
    gameState = addPlayers(playerId, gameState)

    // Load the previous rolls
    let prevRolls
    if (!gameState.rollHistory || gameState.rollHistory.length == 0) {
        gameState.rollHistory = []
        prevRolls = initialRolls
    } else {
        // Append to the roll history from prevRolls to lockedRolls (for when multiple are selected)
        const { rollHistory } = gameState
        prevRolls = rollHistory[rollHistory.length - 1]

        // Clone previousRolls as appendRolls to avoid mutating the previousRolls
        let appendRolls = JSON.parse(JSON.stringify(prevRolls))

        lockedRolls.forEach((lockedRoll, index) => {
            if (lockedRoll.locked && !prevRolls[index].locked) {
                appendRolls[index].selected = true
                rollHistory.push(appendRolls)

                // Clone as a new appendRolls for the next iteration
                appendRolls = JSON.parse(JSON.stringify(appendRolls))
            }
        })

        prevRolls = rollHistory[rollHistory.length - 1]
    }

    const newRolls = prevRolls.map((roll) => {
        // Lock the selected rolls
        if (roll.selected && !roll.locked) {
            return { ...roll, locked: true, selected: false }
        }

        // Roll the unselected dice
        if (!roll.selected && !roll.locked) {
            const newValue = Math.floor(Math.random() * 6) + 1

            return { ...roll, value: newValue }
        }

        return roll
    })

    gameState.rollHistory.push(newRolls)

    // If all rolls are locked, end the turn
    if (newRolls.every(roll => roll.locked)) {
        gameState = endTurn(playerId, gameState, newRolls)
    }

    return gameState
}


const addPlayers = (playerId, gameState) => {
    if (!gameState.players)
        gameState.players = []

    if (!gameState.created_by)
        gameState.created_by = playerId

    gameState.current_turn = playerId // TODO remove

    const { players } = gameState

    if (!players.find(player => player.id == playerId)) {
        players.push({ id: playerId })
    }

    return gameState
}


const endTurn = (playerId, gameState, rolls) => {
    const player = gameState.players.find(player => player.id == playerId)
    const opponent = gameState.players.find(player => player.id != playerId)

    // Pass the turn
    gameState.current_turn = opponent?.id
    gameState.turn += 1

    // Set the player's score
    player.score = calculateScore(rolls)

    gameState = processTurn(playerId, gameState)

    return gameState
}


const processTurn = (playerId, gameState) => {
    const player = gameState.players.find(player => player.id == playerId)
    const opponent = gameState.players.find(player => player.id != playerId)

    // If the opponent has already sent their score, determine the winner
    let opponentScore = opponent?.score

    if (opponentScore != null && opponentScore != -1) {
        gameState.subcaption = `${opponentScore}-${score}`

        if (player.score > opponentScore) {
            gameState.winner = playerId
        } else if (player.score < opponentScore) {
            gameState.winner = opponent.id
        } else {
            gameState.winner = "draw"
        }
    } else {
        gameState.subcaption = `To Beat: ${player.score}`
    }

    return gameState
}


export const calculateScore = (rolls) => {
    const filteredRolls = rolls.filter(roll => roll.locked || roll.selected)
    let hasOne = false
    let hasFour = false
    let score = 0

    filteredRolls.forEach(roll => {
        if (roll.value === 1 && !hasOne) {
            hasOne = true
        } else if (roll.value === 4 && !hasFour) {
            hasFour = true
        } else {
            score += roll.value
        }
    })

    return (hasOne && hasFour) ? score : 0
}


export default Midnight