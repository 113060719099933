import React from 'react'
import toast from 'react-hot-toast'

import { Avatar, Button, Card, CardBody } from "@nextui-org/react";

import withRouter from '../components/withRouter'
import GamePage from '../components/GamePage'

import { calculateScore, initialRolls } from '../games/midnight.mjs'
import { playAudio } from '../components/AudioPlayer'
import MidnightAudio from '../components/midnight/MidnightAudio'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGear,
  faCircleQuestion,
  faForwardStep,
  faDice,
  faPaperPlane
} from '@fortawesome/free-solid-svg-icons'

// import '../styles/Midnight.css'
// import '../styles/new.css'

class Midnight extends GamePage {
  constructor(props) {
    super(props)

    this.state.score = 0
    this.state.rolls = initialRolls
  }

  componentDidMount() {
    super.componentDidMount()

    // Listen for orientation change
    if (screen.orientation) { // Property doesn't exist on screen in IE11   
      screen.orientation.addEventListener("change", this.orientationChange);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount()

    // Stop listening for orientation change
    if (screen.orientation) { // Property doesn't exist on screen in IE11
      screen.orientation.removeEventListener("change", this.orientationChange);
    }
  }

  orientationChange = () => {
    window.getComputedStyle(document.body)
  }


  toggleSelectDice = (index) => {
    const { rolls } = this.state
    if (!rolls[index]) return

    // Don't select dice if they haven't been rolled yet
    if (rolls.every(roll => roll.value === 0)) return

    // Don't deselect final dice
    if (rolls.filter(roll => roll.locked).length >= 5 && rolls[index]?.selected) return

    // Mark the dice as selected
    const newRolls = rolls.map((roll, idx) => {
      if (idx === index) {
        return { ...roll, selected: !roll.selected }
      }

      return roll
    })

    playAudio('click')

    this.setState({ rolls: newRolls, score: calculateScore(newRolls) })
  }


  rollPressed = async () => {
    const { replaying, rolls, gameState } = this.state

    // Skip replay pressed
    if (replaying) {
      playAudio('click')
      this.finishReplay()
      return
    }

    // Rematch pressed
    if (gameState.winner) {
      playAudio('send')
      this.rematch()
      return
    }

    // Play click sound if every roll is selected or locked
    playAudio('click')

    // Lock the selected rolls
    const lockedRolls = rolls.map(roll => {
      if (roll.selected && !roll.locked) {
        return { ...roll, locked: true, selected: false }
      }

      return roll
    })

    this.setState({ rolls: lockedRolls })

    const newGameState = await this.updateGame("rollDice", { lockedRolls })

    if (!newGameState || !newGameState.rollHistory) {
      return
    }

    const newRolls = newGameState.rollHistory[newGameState.rollHistory.length - 1]

    if (!newRolls.every(roll => roll.locked || roll.selected)) {
      playAudio('roll')
      this.rollIframes(newRolls)
    }

    this.setState({ rolls: newRolls, score: calculateScore(newRolls) }, () => {
      // If there is only one dice left, select it
      const unselectedRolls = newRolls.filter(roll => !roll.selected && !roll.locked)
      if (unselectedRolls.length === 1) {
        this.selectLastDice()
      }

      // Every roll is locked, end the turn
      if (newRolls.every(roll => roll.locked)) {
        if (gameState.winner) {
          this.displayWinner()
        } else {
          playAudio('send')
          toast.dismiss()
          toast.success("Sent score: " + this.state.score)
        }
      }
    })
  }


  selectLastDice = async () => {
    await new Promise(r => setTimeout(r, 1250))

    // Select the last dice
    const index = this.state.rolls.findIndex(roll => !roll.selected && !roll.locked)
    this.toggleSelectDice(index)

    await new Promise(r => setTimeout(r, 1000))

    // Lock the last dice
    this.rollPressed()
  }


  rollIframes = (rolls, force) => {
    rolls.map((roll, index) => {
      if ((roll.selected || roll.locked) && !force) return

      // Find the iframe element corresponding to this roll
      const iframe = document.querySelector(`iframe[title="Dice ${index + 1}"]`)
      if (iframe && iframe.contentWindow && typeof iframe.contentWindow.rollValue === 'function') {
        iframe.contentWindow.rollValue(roll.value)
      }
    })
  }


  initialize = () => {
    super.initialize()

    const { gameState } = this.state

    if (gameState.rollHistory?.length > 0 && this.isMyTurn()) {
      const rolls = gameState.rollHistory[gameState.rollHistory.length - 1]

      // If the last roll is locked, roll history is opponent's turn so play replay
      if (rolls.every(roll => roll.locked)) {
        this.startReplay()
        return
      }

      // If the last roll is not locked, roll history is my turn so update rolls
      this.setState({ rolls, score: calculateScore(rolls) })
      this.rollIframes(rolls, true)
    }
  }


  replay = async () => {
    const { rollHistory } = this.state.gameState

    this.setState({ rolls: initialRolls, score: 0 })

    await new Promise(r => setTimeout(r, 1000))

    let skipped = false
    for (const rolls of rollHistory) {
      if (!this.state.replaying) {
        skipped = true
        break
      }

      this.setState({ rolls, score: calculateScore(rolls) })

      let timeout = 1250

      if (rolls.every(roll => !roll.selected) && !rolls.every(roll => roll.locked)) {
        playAudio('roll')

        timeout = 1500

        this.rollIframes(rolls)
      } else {
        playAudio('click')
      }

      await new Promise(r => setTimeout(r, timeout))
    }

    const rolls = rollHistory[rollHistory.length - 1]
    this.rollIframes(rolls, skipped)

    this.setState({
      rolls: rolls,
      score: calculateScore(rolls),
    })

    this.finishReplay()

    if (skipped) {
      await new Promise(r => setTimeout(r, 1500))
    }

    if (!this.state.gameState.winner) {
      playAudio("send")
      toast.success("To Beat: " + calculateScore(rollHistory[rollHistory.length - 1]))

      await new Promise(r => setTimeout(r, 2000))

      toast.dismiss()
      this.setState({ rolls: initialRolls, score: 0 })
      // this.updateGameState({ rollHistory: [] })
    } else {
      this.displayWinner()
    }
  }


  myScore = () => {
    const { gameState } = this.state
    const myPlayer = gameState.players?.find(player => player.id === this.context.playerId)
    if (myPlayer?.score != null && myPlayer?.score !== -1) {
      return myPlayer.score
    }
    if (this.isMyTurn() && !this.state.replaying) {
      return this.state.score
    }
    return "--"
  }


  opponentScore = () => {
    const { gameState, replaying, score } = this.state
    if (replaying) {
      return score
    }

    const opponent = gameState.players?.find(player => player.id !== this.context.playerId)
    if (opponent?.score != null && opponent?.score !== -1) {
      return opponent.score
    }

    return "--"
  }

  render() {
    const { rolls, gameState, replaying } = this.state

    return (
      <div className="h-full flex flex-col justify-content-center bg-cover bg-[url('../../public/1424/1424-bg.jpg')] p-safe">
        <div className="h-full flex flex-col mx-auto my-auto py-14 tall:md:py-20 short:py-5">
          <div className="flex-none mx-auto grid grid-cols-1 short:grid-cols-2 place-items-center gap-4 mb-6">
            <div className="text-center">
              <h5
                className="text-shadow shadow-black relative z-10 text-white font-bold text-xl tall:text-2xl -mb-1"
              >
                {this.isMyTurn() && !replaying ? "Spectral" : "Davey"}
              </h5>

              <Avatar
                src="images/pengy.png"
                className="w-16 h-11 tall:w-20 tall:h-14 mx-auto bg-default"
                isBordered
                color="success"
              />
            </div>

            <Card
              className="flex-none bg-zinc-900 bg-opacity-90 mx-auto"
            >
              <CardBody className="p-3 px-6">
                <p
                  className="text-white text-shadow text-center font-bold text-xl tall:text-2xl md:text-2xl"
                >
                  Score: {this.myScore()}
                  <br />
                  Opponent: {this.opponentScore()}
                </p>
              </CardBody>
            </Card>
          </div>

          <div
            className={"grow shrink grid gap-6 place-items-center overflow-hidden mx-auto " +
              "grid-cols-2 sm:grid-cols-3 portrait:grid-cols-2 short:grid-cols-6 " +
              "max-w-[240px] tall:max-w-[480px] sm:max-w-[320px] md:max-w-[480px] portrait:max-w-[240px] portrait:tall:max-w-[480px] tall:lg:max-w-[560px] tall:xl:max-w-[720px] landscape:short:max-w-full px-5"
            }
          >
            {rolls.map((roll, idx) => (
              <Card key={idx} className="aspect-square bg-zinc-900 bg-opacity-90 max-h-full">
                <iframe
                  className="w-full h-full"
                  style={{ pointerEvents: 'none', transform: 'scale(1.05)' }}
                  src={`1424/dice/dice.html?color=fff&opacity=0.98`}
                  title={`Dice ${idx + 1}`}
                  scrolling="no"
                ></iframe>
              </Card>
            ))}
          </div>

          <Button size="lg" className="flex-none mt-6 place-self-center font-bold rounded-full tall:text-xl tall:p-8 md:text-2xl md:p-8" color="primary">
            <span className="text-shadow">
              {replaying ? "SKIP" : (gameState.winner ? "REMATCH" : (rolls.every(roll => roll.value == 0) ? 'ROLL' : 'KEEP'))}
            </span>

            <FontAwesomeIcon
              icon={replaying ? faForwardStep : (gameState.winner ? faPaperPlane : faDice)}
            />
          </Button>
        </div>
      </div>
    )
    return (
      <div className="midnight-bg justify-content-center align-items-center d-flex">
        <MidnightAudio />
        <div className="grid grid-cols-2 md:grid-cols-3 grid-rows-2 gap-4 h-fit bg-content1">
          <div>

          </div>
        </div>
        <div className="shrink grid grid-cols-2 sm:grid-cols-3 gap-6 place-items-center">
          {rolls.map((roll, idx) => (
            <Card className="aspect-square bg-zinc-900 bg-opacity-90">
              <iframe
                className="w-full h-full"
                style={{ pointerEvents: 'none', transform: 'scale(1.05)' }}
                src={`1424/dice/dice.html?color=fff&opacity=0.98`}
                title={`Dice ${idx + 1}`}
                scrolling="no"
              ></iframe>
            </Card>
          ))}
        </div>


        <div className="midnight-content" style={{ maxWidth: 600 }}>
          <div>
            <Row className="mb-4 mx-0" style={{ marginTop: -20 }}>
              <Col xs="3" className="align-self-center text-center">
                <Button
                  size="lg"
                  variant="dark-gray"
                  className="rounded-circle bg-opacity-50 p-0"
                  style={{
                    width: 40,
                    height: 40
                  }}
                >
                  <FontAwesomeIcon icon={faCircleQuestion} />
                </Button>
              </Col>
              <Col xs="6" className="text-center">
                <h5
                  className="text-white text-shadow fw-bold"
                  style={{
                    textShadow: '2px 2px 4px rgba(0,0,0,1)',
                    marginBottom: -4
                  }}
                >
                  {this.isMyTurn() && !replaying ? "You" : "Them"}
                </h5>

                <img alt="GamePenguin Icon" src="images/icon.png" width="60" style={{ borderRadius: 30 }} />
              </Col>
              <Col xs="3" className="align-self-center text-center">
                <Button
                  size="lg"
                  variant="dark-gray"
                  className="rounded-circle bg-opacity-50 p-0"
                  style={{
                    width: 40,
                    height: 40
                  }}
                >
                  <FontAwesomeIcon icon={faGear} />
                </Button>
              </Col>
            </Row>

            <div
              className="bg-dark-gray bg-opacity-50 pt-2 pb-1 rounded"
            >
              <h1
                className="text-white text-shadow text-center fw-bold"
                style={{
                  textShadow: '2px 2px 4px rgba(0,0,0,1)'
                }}
              >
                Score: {this.myScore()}
                <br />
                Opponent: {this.opponentScore()}
              </h1>
            </div>
          </div>

          <Row className="mx-3" style={{ transform: 'scale(0.8)', marginTop: -20, marginBottom: -32 }}>
            {rolls.map((roll, idx) => (
              <Col sm={4} xs={6} key={idx} className="mb-3">
                <div
                  onClick={() => !roll.locked && this.toggleSelectDice(idx)}
                  style={{ cursor: roll.locked ? 'not-allowed' : 'pointer' }}
                  className={`${roll.locked ? 'bg-secondary bg-opacity-75' : roll.selected ? 'bg-success' : 'bg-dark-gray bg-opacity-75'} rounded position-relative`}
                >
                  {roll.l && <i className="fas fa-lock position-absolute" style={{ top: 10, right: 10 }}></i>}
                  <iframe
                    style={{ pointerEvents: 'none' }}
                    src={`1424/dice/dice.html?color=fff&opacity=0.98`}
                    width="100%"
                    height="100%"
                    title={`Dice ${idx + 1}`}
                    scrolling="no"
                  ></iframe>
                </div>
              </Col>
            ))}
          </Row>

          <div className="text-center">
            <Button
              variant="primary"
              size="lg"
              className="fw-bolder px-4"
              disabled={!replaying && !gameState.winner && (!this.isMyTurn() ||
                (rolls.every(roll => !roll.selected) && rolls.every(roll => roll.value != 0)
                  || (rolls.filter(roll => !roll.selected && !roll.locked).length < 1 && rolls.filter(roll => roll.selected).length <= 1)))
              }
              onClick={this.rollPressed}
              style={{
                transform: 'scale(1.2)',
                textShadow: '2px 2px 4px rgba(0,0,0,1)'
              }}
            >
              {replaying ? "SKIP" : (gameState.winner ? "REMATCH" : (rolls.every(roll => roll.value == 0) ? 'ROLL' : 'KEEP'))}
              <FontAwesomeIcon
                size="sm"
                icon={replaying ? faForwardStep : (gameState.winner ? faPaperPlane : faDice)}
                style={{
                  filter: "drop-shadow(2px 2px 4px rgba(0,0,0,1))"
                }}
                className='ms-2'
              />
            </Button>
          </div>

          <Button variant="secondary" className="d-none" onClick={() => this.replayRollHistory(this.state.gameState.rollHistory)}>Replay Rolls</Button>
        </div>
      </div>
    )
  }
}

export default withRouter(Midnight)