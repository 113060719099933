import { Avatar, Button, Card } from "@nextui-org/react";
import '../styles/new.css'

export default function TestPage() {
    return (
        <div className="h-screen w-screen">
            <div className="grid-container h-full max-w-[512px] mx-auto">
                <Card className="bg-black aspect-square">
                    1
                </Card>

                <Card className="bg-black aspect-square">
                    1
                </Card>

                <Card className="bg-black aspect-square">
                    1
                </Card>

                <Card className="bg-black aspect-square">
                    1
                </Card>

                <Card className="bg-black aspect-square">
                    1
                </Card>

                <Card className="bg-black aspect-square">
                    1
                </Card>
            </div>
        </div>

    )
    return (
        <div className="grid grid-cols-2 sm:grid-cols-3 h-full gap-6 place-items-center mx-auto max-w-[512px]">
            <Card className="bg-black aspect-square w-full">
                1
            </Card>

            <Card className="bg-black aspect-square w-full">
                2
            </Card>

            <Card className="bg-black aspect-square w-full">
                3
            </Card>

            <Card className="bg-black aspect-square w-full">
                4
            </Card>

            <Card className="bg-black aspect-square w-full">
                5
            </Card>

            <Card className="bg-black aspect-square w-full">
                6
            </Card>
        </div>

    )
    return (
        <div className="h-full">
            <div className="grid grid-cols-2 h-full gap-6 items-center content-center">
                <Card className="bg-black aspect-square h-full">
                    <div className="m-auto">1</div>
                </Card>

                <Card className="bg-black aspect-square h-full mx-auto">
                    <div className="m-auto">1</div>
                </Card>

                <Card className="bg-black aspect-square h-full">
                    <div className="m-auto">1</div>
                </Card>

                <Card className="bg-black aspect-square h-full">
                    <div className="m-auto">1</div>
                </Card>
            </div>
        </div>
    )
}