// AppContext.js
import React, { createContext } from 'react'

export const AppContext = createContext()
import { v4 as uuidv4 } from 'uuid';

export class AppProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            games: [
                {
                    name: '1-4-24',
                    image: '1424/1424-banner.jpg',
                    imessage_banner: '1424/1424-banner-imessage',
                    route: 'midnight'
                }
            ],
            canGoBack: false,
            iMessage: false
        }
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        const iMessageParam = searchParams.get('imessage');
        const iMessage = iMessageParam === 'true';

        let playerId;
        if (iMessage) {
            // Check URL for playerId when iMessage is true
            playerId = searchParams.get('playerId') || uuidv4();
            // If no playerId is found in the URL, generate a new one.
            // Do NOT fetch from or save to localStorage because iMessage is true
        } else {
            // When iMessage is not true, use localStorage for playerId
            playerId = localStorage.getItem('playerId') || uuidv4();
            localStorage.setItem('playerId', playerId);
        }

        // Update state with the determined values
        this.setState({ iMessage, playerId });
    }

    sendDataToSwift(data) {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.messageHandler) {
            window.webkit.messageHandlers.messageHandler.postMessage(data)
        }
    }

    currentGame = () => {
        const game = this.state.games.find(g => window.location.hash.includes(g.route))

        return game
    }

    render() {
        return (
            <AppContext.Provider
                value={{
                    ...this.state,
                    setState: (state) => this.setState(state),
                    sendDataToSwift: this.sendDataToSwift,
                    currentGame: this.currentGame
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        )
    }
}
