import React from 'react'
import { HashRouter } from 'react-router-dom'

import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider } from "next-themes";

import { createClient } from "@supabase/supabase-js";

import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';

import { Toaster } from 'react-hot-toast'

import { AppProvider } from './components/AppContext'
import NavigationBar from './components/NavigationBar'
import AnimateLayout from './components/AnimateLayout'

import "./styles/tailwind.css"

const supabase = createClient("https://eobqbhubabexuxhqdwcg.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVvYnFiaHViYWJleHV4aHFkd2NnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDUyMDgxNjAsImV4cCI6MjAyMDc4NDE2MH0.nqxQqHysGkFM9rnj28gt-Jhj6yLShJhkTg-S_s1iUhA");

class App extends React.Component {
    render() {
        return (
            <AppProvider>
                <NextUIProvider>
                    <ThemeProvider attribute="class">
                        <Toaster
                            containerStyle={{ top: '65%', transform: 'translate(0, -50%)' }}
                            toastOptions={{
                                className: 'text-lg bg-content1 bg-opacity-95 text-foreground shadow-lg rounded-full',
                                duration: 5000000000,
                            }}
                        />

                        <HashRouter>
                            <div
                                style={{
                                    height: '100svh',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <NavigationBar />

                                <div style={{ flex: 1, position: 'relative' }}>
                                    <AnimateLayout />
                                </div>
                            </div>
                        </HashRouter>
                    </ThemeProvider>
                </NextUIProvider>

                <Analytics />
                <SpeedInsights />
            </AppProvider>
        )
    }
}

export default App
