import { useEffect, useState } from 'react'

export function useDisableAnimation() {
    const [disableAnimation, setDisableAnimation] = useState(false)

    // Disable Animation for iOS swipe navigation
    useEffect(() => {
        let touchStartX = 0
        let touchEndX = 0

        function handleTouchStart(e) {
            touchStartX = e.changedTouches[0].screenX
        }

        function handleTouchEnd(e) {
            touchEndX = e.changedTouches[0].screenX
            if (Math.abs(touchStartX - touchEndX) > 50) {
                setDisableAnimation(true)
            }
        }

        window.addEventListener('touchstart', handleTouchStart)
        window.addEventListener('touchend', handleTouchEnd)

        return () => {
            window.removeEventListener('touchstart', handleTouchStart)
            window.removeEventListener('touchend', handleTouchEnd)
        }
    }, [])

    useEffect(() => {
        if (disableAnimation) {
            const timeoutId = setTimeout(() => setDisableAnimation(false), 200)
            return () => clearTimeout(timeoutId)
        }
    }, [disableAnimation])

    return disableAnimation
}

export function withDisableAnimation(Component) {
    return function WrappedComponent(props) {
        const disableAnimation = useDisableAnimation()
        return <Component {...props} disableAnimation={disableAnimation} />
    }
}