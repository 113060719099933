import React from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Outlet } from 'react-router-dom'

const pageTransition = {
    type: 'spring',
    stiffness: 600,
    damping: 30,
    mass: 0.5
}

function PageTransition({ isBack, disableAnimation }) {
    const location = useLocation()
    const pageVariants = {
        initial: () => ({
            opacity: 1,
            x: disableAnimation ? 0 : (isBack ? '-100vw' : '100vw'),
        }),
        in: {
            opacity: 1,
            x: 0,
        },
        out: ({ isBack, disableAnimation }) => ({
            opacity: 1,
            x: disableAnimation ? 0 : (isBack ? '100vw' : '-100vw'),
        }),
    }

    return (
        <motion.div
            key={location.key}
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                overflowY: 'scroll',
            }}
            custom={{ isBack, disableAnimation }}
        >
            <Outlet />
        </motion.div>
    )
}

export default PageTransition
