import React from 'react'
import { AppContext } from './AppContext'
import { playAudio } from './AudioPlayer'
import toast from 'react-hot-toast'
import withRouter from './withRouter'

class GamePage extends React.Component {
    constructor(props) {
        super(props)
        const { gameId } = this.props.router.params;

        this.state = {
            turn: 0,
            gameId: gameId,
            gameState: {
                players: []
            },
            replaying: false,
            loaded: false
        }
    }

    componentDidMount() {
        playAudio('bgm')

        window.receiveFromApp = (data) => {
            if (data.state) {
                if (window.winnerDetermined) return

                this.setState({ gameState: data.state })
            }
        }

        this.handleUrlParams()
        this.loadGameState()
    }


    handleUrlParams = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const iMessageParam = urlParams.get('iMessage')
        if (iMessageParam != null) {
            this.context.sendDataToSwift({ action: 'fullscreen' })
            this.context.sendDataToSwift({ action: 'requestGameState' })
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.gameState != this.state.gameState) {
            // this.renderGameState(prevState)
        }

        this.loadGameState()
    }


    loadGameState = async () => {
        if (!this.context.playerId) return
        if (!this.state.gameId) return
        if (this.state.loaded) return

        console.log("Game ID: " + this.state.gameId)
        console.log("Player ID: " + this.context.playerId)

        this.setState({ loaded: true })

        if (this.context.iMessage) {
            // Load game state from iMessage

            return
        }

        try {
            const response = await fetch(`/api/room?id=${this.state.gameId}&playerId=${this.context.playerId}`)
            const data = await response.json()
            const newGameState = data.game_state
            await new Promise(resolve => this.setState({ gameState: newGameState }, resolve))
        } catch (error) {
            // toast.dismiss()
            console.error(error);
            return
        }

        this.initialize()
    }


    async initialize() {
        // TODO do something with initial gamestate.
        const { gameState, replaying } = this.state

        // Show waiting for opponent when it's not my turn
        if (!this.isMyTurn()) {
            if (gameState.winner) {
                this.displayWinner()
            } else {
                toast.dismiss()
                toast.loading('Waiting for opponent...')
            }

            return
        }
    }


    async updateGame(method, params) {
        const { gameId } = this.state
        const game = this.context.currentGame()

        if (this.context.iMessage) {
            try {
                const gameFunctions = await import(`../games/${game.route}.mjs`);
                const newGameState = gameFunctions.default[method](this.context.playerId, this.state.gameState, params)

                await new Promise(resolve => this.setState({ gameState: newGameState }, resolve))

                return newGameState
            } catch (error) {
                console.error(error);
            }
        } else {
            // toast.loading()

            try {
                const response = await fetch(`/api/updateGame`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        id: gameId,
                        playerId: this.context.playerId,
                        name: game.route,
                        method: method,
                        params: params
                    })
                })

                const data = await response.json()
                const newGameState = data
                await new Promise(resolve => this.setState({ gameState: newGameState }, resolve))
                return newGameState
            } catch (error) {
                // toast.dismiss()
                console.error(error);
            }
        }
    }


    componentWillUnmount() {
        delete window.receiveFromApp

        this.finishReplay()
    }


    isMyTurn = () => {
        const { gameState } = this.state

        if (gameState.current_turn == this.context.playerId) {
            return true
        }

        if (!gameState.current_turn && gameState.created_by != this.context.playerId) {
            return true
        }

        return false
    }

    displayWinner = () => {
        const { gameState } = this.state

        toast.dismiss()

        switch (gameState.winner) {
            case this.context.playerId:
                playAudio('win')
                toast('You Win!', { icon: '🎉' })
                break
            case "draw":
                playAudio('click')
                toast("Draw!")
                break
            default:
                playAudio('lose')
                toast.error("You lose!")
                break
        }
    }


    rematch = () => {
        this.context.sendDataToSwift({ action: 'startGame', game: { ...game, rematch: true } })
        this.context.sendDataToSwift({ action: 'dismiss' })
    }

    startReplay = async () => {
        toast.dismiss()
        toast.loading("Replay")

        this.setState({ replaying: true }, () => {
            this.replay()
        })
    }

    replay = async () => {
        this.finishReplay()
    }

    finishReplay = () => {
        this.setState({ replaying: false })
        toast.dismiss()
    }
}

GamePage.contextType = AppContext

export default GamePage