import React from 'react'
import toast from 'react-hot-toast'

import { AppContext } from '../components/AppContext'
import withRouter from '../components/withRouter'
import { Card, CardBody, Spinner } from "@nextui-org/react";


class Home extends React.Component {
    sendDataToSwift(data) {
        if (window.webkit?.messageHandlers?.messageHandler) {
            window.webkit.messageHandlers.messageHandler.postMessage(data)
        }
    }

    startGame(game) {
        const urlParams = new URLSearchParams(window.location.search)
        const imessageParam = urlParams.get('imessage')

        if (!imessageParam) {
            this.props.router.navigate(game.route + "/test")
        } else {
            this.sendDataToSwift({ action: 'startGame', game: game })
        }
    }

    componentDidMount() {
        window.receiveFromApp = (data) => {
            console.log("Data received from app:", data)
        }

        toast.dismiss()
        this.context.setState({ canGoBack: true })

        return
        toast.custom(
            <Card className="w-fit rounded-full shadow-none bg-opacity-95">
                <CardBody className="p-4">
                    <Spinner color="default" classNames={{ circle1: "border-b-foreground", circle2: "border-b-foreground" }} />
                </CardBody>
            </Card>
        )
    }

    componentWillUnmount() {
        delete window.receiveFromApp
    }

    render() {
        return (
            <div className="container mx-auto p-4" style={{ maxWidth: 768 }}>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8">
                    {this.context.games.map((game, idx) => (
                        <div key={idx} className="cursor-pointer" onClick={() => this.startGame(game)}>
                            <Card>
                                <img src={game.image} />
                                <p className="text-center p-1 bg-content1 font-semibold md:text-lg">
                                    {game.name}
                                </p>
                            </Card>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

Home.contextType = AppContext

export default withRouter(Home)
