import React, { useEffect } from 'react'
import useSound from 'use-sound'

export const AudioPlayer = ({ src, id, loop = false, autoPlay = false }) => {
    if (!autoPlay) {
        const [play, { stop }] = useSound(src)

        // update useEffect to clear this variable from window on unmount
        useEffect(() => {
            window[`play_${id}`] = play

            return () => {
                window[`play_${id}`] = null
                stop()
            }
        }, [play])
    } else {
        useEffect(() => {
            function startAutoplay() {
                const audio = document.getElementById(`${id}-sound`)
                if (audio) {
                    audio.play().catch((e) => {
                        // Handle any errors that occur when trying to play the audio
                        console.error('Playback prevented', e)
                    })

                    window.removeEventListener('click', startAutoplay)
                    window.removeEventListener('touchstart', startAutoplay)
                    window.removeEventListener('keydown', startAutoplay)
                    window.removeEventListener('scroll', startAutoplay)
                }
            }

            // Listen for user interaction to autoplay this track
            window.addEventListener('click', startAutoplay)
            window.addEventListener('touchstart', startAutoplay)
            window.addEventListener('keydown', startAutoplay)
            window.addEventListener('scroll', startAutoplay)

            return () => {
                window.removeEventListener('click', startAutoplay)
                window.removeEventListener('touchstart', startAutoplay)
                window.removeEventListener('keydown', startAutoplay)
                window.removeEventListener('scroll', startAutoplay)
            }
        })
    }

    return (
        <audio id={`${id}-sound`} loop={loop} autoPlay={autoPlay} preload="auto">
            <source src={src} type="audio/mp3" />
        </audio>
    )
}

// Define the playAudio function
export const playAudio = (id) => {
    if (window[`play_${id}`]) {
        window[`play_${id}`]()
        return
    }

    const audio = document.getElementById(`${id}-sound`)
    if (audio) {
        audio.pause()
        audio.currentTime = 0 // Restart the audio from the beginning
        audio.play().catch((e) => {
            // Handle any errors that occur when trying to play the audio
            console.error('Playback prevented', e)
        })
    }
}
