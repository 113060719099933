import React from 'react';
import { Navbar, NavbarContent, Avatar } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@nextui-org/react';
import { faChevronLeft, faGear } from '@fortawesome/free-solid-svg-icons';
import withRouter from './withRouter';
import { AppContext } from './AppContext';


class NavigationBar extends React.Component {
    handleBackClick = () => {
        const { navigate } = this.props.router;

        if (this.context.canGoBack) {
            navigate(-1);
        } else {
            navigate("/", { replace: true });
        }
    }

    render() {
        const { location } = this.props.router;
        const isHomePage = location.pathname === '/';
        const isTransparent = !isHomePage
        const game = this.context.currentGame();

        if (this.context.iMessage && !isHomePage) {
            return null;
        }

        return (
            <Navbar className={(isTransparent ? "backdrop-blur-none bg-transparent absolute pt-safe px-safe" : "bg-content1 shadow pt-safe px-safe")} isBordered={!isTransparent}>
                <NavbarContent className="-ml-3">
                    {!isHomePage && (
                        <>
                            <Button
                                size="lg"
                                isIconOnly
                                onClick={this.handleBackClick}
                                className={"md:hidden px-0 rounded-full flex items-center" + (isTransparent ? " bg-content1 shadow bg-opacity-90" : " bg-transparent")}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} size="xl" />
                            </Button>

                            <Button
                                size="lg"
                                onClick={this.handleBackClick}
                                className={"hidden md:flex px-0 rounded-full items-center" + (isTransparent ? " bg-content1 shadow bg-opacity-90" : " bg-transparent")}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} size="xl" />

                                <img
                                    className="hidden rounded-full md:inline w-12 h-auto -ml-1"
                                    alt="GamePenguin"
                                    src="images/icon.png"
                                />
                            </Button>
                        </>

                    )}
                </NavbarContent>

                <NavbarContent justify="center">
                    {!isTransparent &&
                        <div className="flex items-center">
                            <img
                                className="rounded-full mr-3 w-12 h-auto md:w-14"
                                alt={isHomePage ? 'GamePenguin' : (game ? game.name : 'GamePenguin')}
                                src={isHomePage ? "images/icon.png" : (game ? game.image : "images/icon.png")}
                            />

                            <p className="font-bold text-lg md:text-xl align-middle">
                                {isHomePage ? 'GamePenguin' : (game ? game.name : 'GamePenguin')}
                            </p>
                        </div>
                    }
                </NavbarContent>

                <NavbarContent justify="end" className="-mr-2">
                    <Avatar
                        src="images/pengy.png"
                        as="button" className="h-9 w-12 md:h-10 md:w-14 lg:h-11 lg:w-16 bg-content3 text-foreground shadow-lg" isBordered color={isHomePage ? "warning" : "success"} />
                </NavbarContent>
            </Navbar>
        );
    }
}

NavigationBar.contextType = AppContext;

export default withRouter(NavigationBar);
