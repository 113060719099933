// AnimateLayout.js
import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation, useNavigationType } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import PageTransition from './PageTransition'
import HomePage from '../pages/Home'
import MidnightPage from '../pages/Midnight'
import TestPage from '../pages/Test'
import { useDisableAnimation } from './DisableAnimation'

function AnimateLayout() {
    const location = useLocation()
    const navigationType = useNavigationType()
    const disableAnimation = useDisableAnimation()
    const [historyStack, setHistoryStack] = useState([])

    // Track history to determine if POP is back or forward
    useEffect(() => {
        setHistoryStack((prev) => {
            const newStack = [...prev]
            const currentIndex = window.history.state?.idx

            if (currentIndex != null) {
                if (newStack.length === 0) {
                    newStack.push(currentIndex)
                } else if (currentIndex > newStack[newStack.length - 1]) {
                    newStack.push(currentIndex)
                } else if (currentIndex < newStack[newStack.length - 1]) {
                    newStack.pop()
                }
            }

            return newStack
        })
    }, [location])

    let isBack = navigationType === 'POP' && historyStack != null && historyStack.length > 1 && window.history.state?.idx < historyStack[historyStack.length - 1]

    if (location.pathname === '/') {
        isBack = true
    }

    return (
        <AnimatePresence initial={false} custom={{ isBack, disableAnimation }}>
            <Routes location={location} key={location.pathname}>
                <Route element={<PageTransition isBack={isBack} disableAnimation={disableAnimation} />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/midnight/:gameId" element={<MidnightPage />} />
                    <Route path="/test" element={<TestPage />} />
                </Route>
            </Routes>
        </AnimatePresence>
    )
}

export default AnimateLayout
